import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'

const RegularBannerFutures = ({data}) => {
    return (
        <div className="main-product">
            <div className={"container " + data.class}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={data.image} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="features-product-content">
                            <div>
                                {data.title}
                                <p>{data.subtitle}</p>
                                {data.link && <div className="option-item">
                                    <Link to={data.link} className="default-btn" >
                                        Learn More
                                    </Link>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegularBannerFutures
import React from 'react'
import trackingImg from '../../assets/images/cards/block-tracking.svg'
import unsubscribeImg from '../../assets/images/cards/unsubscribe.svg'
import configurationImg from '../../assets/images/cards/encryption.svg'
import serverImg from '../../assets/images/cards/conect-server.svg'
import groupImg from '../../assets/images/cards/group-mail.svg'
import managementImg from '../../assets/images/cards/span-management.svg'
import signatureImg from '../../assets/images/cards/signature.svg'
import privateNotificationsImg from '../../assets/images/cards/private-notifications-card.svg'
import backupImg from '../../assets/images/cards/backup.svg'
import gemImg from '../../assets/images/cards/gem-ai.svg'
import aliasImg from '../../assets/images/cards/aliases.svg'
import badgeImg from '../../assets/images/cards/badge.png'
import themeImg from '../../assets/images/cards/themes.svg'
import shareEmailImg from '../../assets/images/cards/share-email.svg'
import addFreeImg from '../../assets/images/cards/add-free.svg'
import messagingBridgeImg from '../../assets/images/cards/messagingBridgebadge.svg'
import blueMailWork from '../../assets/images/cards/blueMailWork.svg'
import { Link } from 'gatsby'


const HorizontalCards = ({data}) => {
    return (
        <div class="container box-cards p-4">
            <div className='row'>
                <div className='col'>
                    <h1 className='text-center' style={{marginBottom: "60px"}}>And so much <span>More!</span></h1>
                </div>
            </div>
            <div class="row flex-nowrap">
                <Link to='/blog/pixel-blocking/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={trackingImg} />
                        <p>Block Tracking Images</p>
                    </div>
                </div>
                </Link>
                <Link to='/blog/one-tap-to-unsubscribe/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={unsubscribeImg} />
                        <p>One-Click Unsubscribe</p>
                    </div>
                </div>
                </Link>
                <Link to='/security/'>
                    <div class="col">
                        <div class="card card-block">
                            <img src={configurationImg} />
                            <p>Encryption</p>
                        </div>
                    </div>
                </Link>
                <Link to='/features-functions/instant-push/'>
                    <div class="col">
                        <div class="card card-block">
                            <img src={serverImg} />
                            <p>Direct Connection to Server</p>
                        </div>
                    </div>
                </Link>
                <Link to='/features-functions/group-mail/'>
                    <div class="col">
                        <div class="card card-block">
                            <img src={groupImg} />
                            <p>Group Mail</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div class="row flex-nowrap">
                <Link to='/security/block-list/'>
                    <div class="col">
                        <div class="card card-block">
                            <img src={managementImg} />
                            <p>Spam Management</p>
                        </div>
                    </div>
                </Link>
                <Link to='/features-functions/messaging-bridge/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={messagingBridgeImg} />
                        <p>Messaging Bridge</p>
                    </div>
                </div>
                </Link>
            
                <div class="col">
                    <Link to='/features-functions/backup'>
                    <div class="card card-block">
                        <img src={backupImg} />
                        <p>Backup</p>
                    </div>
                    </Link>
                </div>
                <Link to='/corporate/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={blueMailWork} />
                        <p>BlueMail Work</p> 
                    </div>
                </div>
                </Link>

            
                <Link to='/features-functions/aliases/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={aliasImg} />
                        <p>Aliases</p>
                    </div>
                </div>
                </Link>
            </div>
            <div class="row flex-nowrap">
                <Link to='/video/'>
                    <div class="col">
                        <div class="card card-block">
                            <img src={badgeImg} />
                            <p>Video Meetings</p>
                        </div>
                    </div>
                </Link>
                <Link to='/features-functions/themes/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={themeImg} />
                        <p>Themes</p>
                    </div>
                </div>
                </Link>
                <Link to='/features-functions/share/'>
                    <div class="col">
                        <div class="card card-block">
                            <img src={shareEmailImg} />
                            <p>Share Email</p>
                        </div>
                    </div>
                </Link>
                <Link to='/features-functions/ad-free/'>
                <div class="col">
                    <div class="card card-block">
                        <img src={addFreeImg} />
                        <p>Ad-free</p>
                    </div>
                </div>
                </Link>
                <Link to='/features-functions/private-notifications'>
                <div class="col">
                    <div class="card card-block">
                        <img src={privateNotificationsImg} />
                        <p>Private Notifications</p>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    )
}

export default HorizontalCards

import React from 'react';
import visually1 from '../../assets/images/features/visually1.png';
import visually2 from '../../assets/images/features/visually2.png';
import visually3 from '../../assets/images/features/visually3.png';
import visually4 from '../../assets/images/features/visually4.png';
import Loadable from '@loadable/component';
import { v4 as uuidv4 } from 'uuid';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { config } from 'react-spring';

const Carousel = Loadable(() => import('react-spring-3d-carousel'));

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    responsive: [
        {
            breakpoint: 0,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const VisuallyAppealing = () => {
    const [display, setDisplay] = React.useState(false);
    const [goToSlide, setGoToSlide] = React.useState(0);
    const [offsetRadius, setOffsetRadius] = React.useState(2);
    const [showNavigation, setShowNavigation] = React.useState(true);
    const [_config, setConfig] = React.useState(config.default);

    const slides = [
        {
            key: uuidv4(),
            content: <img src={visually1} alt="1" style={{ maxWidth: '100%' }} />,
        },
        {
            key: uuidv4(),
            content: <img src={visually2} alt="2" style={{ maxWidth: '100%' }} />,
        },
        {
            key: uuidv4(),
            content: <img src={visually3} alt="3" style={{ maxWidth: '100%' }} />,
        },
        {
            key: uuidv4(),
            content: <img src={visually4} alt="4" style={{ maxWidth: '100%' }} />,
        },
    ].map((slide, index) => {
        return { ...slide, onClick: () => setGoToSlide(index) };
    });

    React.useEffect(() => {
        setDisplay(true);
    }, []);

    return (
        <div className="visually-area">
            <div className="section-title">
                <h1 style={{ color: '#fff' }}>visually appealing</h1>
            </div>
            {typeof window !== 'undefined' && (
                <div className="testimonials-wrapper">
                    <Carousel
                        slides={slides}
                        goToSlide={goToSlide}
                        offsetRadius={offsetRadius}
                        showNavigation={showNavigation}
                        animationConfig={_config}
                    />
                </div>
            )}

            {display ? (
                <Slider
                    className="testimonials-wrapper-mobile testimonials-slides"
                    {...options}
                >
                    {slides.map((slide, index) => (
                        <div className="single-testimonials-item" key={index}>
                            {slide.content}
                        </div>
                    ))}
                </Slider>
            ) : (
                ''
            )}
        </div>
    );
};

export default VisuallyAppealing;

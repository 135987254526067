import React from 'react';
import scrollableImg1 from '../../assets/images/features/dynamic-conversations.webp';
import scrollableImg2 from '../../assets/images/features/any-device.webp';
import scrollableImg3 from '../../assets/images/features/android-wear-apple.webp';
import scrollableImg4 from '../../assets/images/features/Android-Apple-Watch.webp';
import Loadable from '@loadable/component';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CaseStudySlider = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
    };

    return (
        <div className="container case-study-area" style={{ margin: 'auto' }}>
            {display ? (
                <Slider {...settings}>
                    {/* <div className="container single-case-study-item">
                        <img src={scrollableImg1} alt="scrollableImg" />
                        <div className="container">
                            <div className="case-study-content">
                                <div>
                                    <h2>Dynamic Conversations</h2>
                                    <p>Description for Dynamic Conversations.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container single-case-study-item">
                        <img src={scrollableImg2} alt="scrollableImg" />
                        <div className="container">
                            <div className="case-study-content">
                                <div>
                                    <h2>Any Device</h2>
                                    <p>Description for Any Device.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="container single-case-study-item">
                        <img src={scrollableImg4} alt="scrollableImg" />
                        <div className="container">
                            <div className="case-study-content">
                                <div>
                                    <h2>Android Wear & Apple Watch</h2>
                                    <p>Intelligent email on your wrist and any other wearable devices</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="container single-case-study-item">
                        <img src={scrollableImg4} alt="scrollableImg" />
                        <div className="container">
                            <div className="case-study-content">
                                <div>
                                    <h2>Android-Apple-Watch</h2>
                                    <p>Description for Android-Apple-Watch.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            ) : (
                ''
            )}
        </div>
    );
};

export default CaseStudySlider;

import React from "react";
import fetch from 'node-fetch' 
import { osName, isIOS, isAndroid } from 'react-device-detect'
import { Base64 } from "js-base64";

try {
  const sesCampaign = ["?comeback&", "?WelcomeRegNew&"];
  let currentCampaign;
  
  function isCampaignIncluded() {
    for (let campaign of sesCampaign) {
      if (window.location.href.includes(campaign)) {
        currentCampaign = campaign
        return true;
      }
    }
    return false;
  }

  function decodeEncodedJson(encodedJson) {
    let base64Encoded = encodedJson.slice(0,2) + encodedJson.slice(3,5) + encodedJson.slice(6);
    let decoded = Base64.decode(base64Encoded);
    return decoded;
  }
  
  const checkAnalytics = typeof window !== 'undefined' ? isCampaignIncluded() : '';
    
  if(checkAnalytics) {
    const accesedUrlReceived = typeof window !== 'undefined' ? (window.location.href.split(currentCampaign).pop().split('&')) : '';  
    let accesedUrl = decodeEncodedJson(accesedUrlReceived[0]);
    try {
      accesedUrl = JSON.parse(accesedUrl);
    } catch (e) {
      accesedUrl = {};
    }

    const device = isAndroid ? 'android' : isIOS ? 'ios' : 'desktop';
    const os = osName;
  
    // get value by name
    function getParameterByName(name, url = typeof window !== 'undefined' ? window.location.href : '') {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  
    const utmS = getParameterByName('utm_source')
    const utmM = getParameterByName('utm_medium')
    const utmC = getParameterByName('utm_content')
    const btn = getParameterByName('btn')
    
  
    if (accesedUrl && accesedUrl["uid"]) {
      accesedUrl["uid"] = new Number(accesedUrl["uid"]);
    }
    let result = {
      event: 'ses_general_campaign_message_action_button_clicked',  
      os: os,
      device: device,
      ...accesedUrl,
      btn: btn ? btn : '',
      utm_source: utmS ? utmS : '',
      utm_medium: utmM ? utmM : '',
      utm_content: utmC ? utmC : ''
    }
  
    // console.log(result, 'here')
    
    function safeStringify(obj) {
        const seen = [];
        return JSON.stringify(obj, (key, val) => {
          if (val != null && typeof val === 'object') {
            if (seen.indexOf(val) >= 0) {
              return;
            }
            seen.push(val);
          }
          return val;
        });
    }
    
    const dataString = safeStringify(result);
    
    fetch("https://reg3.bluemailapp.com/analytics", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: dataString
    });
  }
} catch (e) {

}